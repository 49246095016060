<!-- Navigation -->
<fuse-vertical-navigation
    #navigationComponent
    class="dark print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="text-white text-center font-bold tracking-widest mt-1">NOTEBOOK</div>
        <div class="flex items-center justify-center h-18 pt-1 px-2 pb-3 border-b border-b-white mb-8" *ngrxLet="logo$; let logo">
            <div class="h-full w-full full-navigation-logo" [style.background-image]="'url(' + (logo || '/assets/images/logo/logo.png') + ')'"></div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="book-content flex flex-col flex-auto w-full min-w-0 rounded-bl-[20px]" *ngrxLet="organisation$; let organisation">
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>
    <div class="absolute -top-7 right-2">
        <svg id="bookmark" width="14" height="124" viewBox="0 0 14 124" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H14V124L7 116.122L0 124V0Z" fill="url(#paint0_linear_409_105)" />
            <defs>
                <linearGradient id="paint0_linear_409_105" x1="7" y1="0" x2="7" y2="124" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#20BEC6" />
                    <stop offset="1" stop-color="#9ACC59" />
                </linearGradient>
            </defs>
        </svg>
    </div>
</div>
