import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';

@Pipe({
    name: 'compareDates'
})
export class CompareDatesPipe implements PipeTransform {
    public static transform(value: string | Moment | Date, compareWith: string | Moment | Date): boolean {
        return moment(value).isAfter(compareWith);
    }

    public transform(value: string | Moment | Date, compareWith: string | Moment | Date): boolean {
        return CompareDatesPipe.transform(value, compareWith);
    }
}
