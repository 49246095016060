import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthDispatchers } from '@store/auth';
import { ErrorResponseType } from '@core/types/http/respone.type';
import { SnackbarService } from '@core/services/services/snackbar.service';
import { forIn, size } from 'lodash-es';
import { AuthService } from '@core/services/resource/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AppErrorHandler implements ErrorHandler {
    constructor(
        private injector: Injector,
        private snackbarService: SnackbarService,
        private authService: AuthService
    ) {}

    handleError<errors>(error: ErrorResponseType<errors> | Error | HttpErrorResponse | any): void {
        if (error instanceof HttpErrorResponse) {
            return this.handleHttpError(error);
        }
        // console.error('There was an ERROR:', error);
        // const errorPayload = isString(error)
        //   ? { message: error }
        //   : { message: `${error?.message}\n${error?.error?.error?.message}` };
        // todo: notify about the error
        // appApi.notifyError(errorPayload);
    }

    handleHttpError(error: HttpErrorResponse): void {
        console.error(error);
        if (error?.status === 401 || error?.status === 419) {
            this.authService.redirectUrl = location?.pathname;

            const authDispatchers = this.injector.get(AuthDispatchers);
            authDispatchers.invalidateToken();

            if (error?.status === 419) {
                this.snackbarService.show('error', 'Unlock your session', 'Your session is locked due to inactivity', {
                    timeOut: 8000
                });
            }
        } else if (error?.status === 422 && error.error.data && size(error.error.data) > 0) {
            let message = '';
            forIn(error.error.data, (value, key) => {
                if (value.length) {
                    value.forEach(v => {
                        message += '● ' + v?.message ?? v + '</br>';
                    });
                }
            });
            this.snackbarService.show('error', message, error.error.message, { timeOut: 8000 });
        } else {
            this.snackbarService.show('error', error.error.message);
        }
    }
}
