import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { ListableResponseType } from '@core/types/http/listable-response.type';
import { IShipment, IShipmentTimelineItem } from '@models/shipment';
import { ShipmentAction } from '@modules/ucontrol/shipments/view/components/right-side/details/menu/actions/shipment.actions';

export type CourierTrackingRequest = {
    tracking_data: object;
    company_id: number;
    connection_id: number;
    with?: string;
};

@Injectable({
    providedIn: 'root'
})
export class ShipmentsService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadShipments(
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<ListableResponseType<IShipment>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('ucontrol/shipments', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadShipment(id: number, params: { [key: string]: any } = {}): Observable<IShipment> {
        return this.httpService
            .get(
                this.createUrlWithQueryParameters(`ucontrol/shipments/${id}`, (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    createShipment(data?: any): Observable<IShipment> {
        return this.httpService
            .post(this.createUrl('ucontrol/shipments'), data)
            .pipe(map((response: any) => response.data));
    }

    updateShipment(id?: number, data?: any): Observable<IShipment> {
        return this.httpService
            .put(this.createUrl(`ucontrol/shipments/${id}`), data)
            .pipe(map((response: any) => response.data));
    }

    deleteShipment(shipmentId: number): Observable<IShipment> {
        return this.httpService
            .delete(this.createUrl(`ucontrol/shipments/${shipmentId}`))
            .pipe(map((response: any) => response.data));
    }

    action(shipmentId: number, action: ShipmentAction | string, data: any = {}): Observable<IShipment> {
        const actionStr = typeof action === 'string' ? action : action.code;

        return this.httpService
            .post(this.createUrl(`ucontrol/shipments/${shipmentId}/action/${actionStr}`), data)
            .pipe(map((response: any) => response.data));
    }

    loadTimeline(shipmentId: number): Observable<IShipmentTimelineItem[]> {
        return this.httpService
            .get(this.createUrl(`ucontrol/shipments/${shipmentId}/timeline`))
            .pipe(map((response: any) => response.data));
    }

    import(data: any): Observable<any> {
        return this.httpService
            .post(this.createUrl('ucontrol/shipments/import'), data)
            .pipe(map((response: any) => response.data));
    }

    exportShipments(
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<any> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('ucontrol/shipments/export', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }
}
