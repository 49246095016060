import { chain, forEach, forIn, get, isPlainObject, mapValues, omit, unionBy } from 'lodash-es';
import { WritableSignal } from '@angular/core';
import { FacetedFilter, IFacets, ListFilterRaw, ListFilterSet, ListFilterValue } from '@core/types/filter.types';

export type Filters = Record<string, any | any[]>;

export type InputFilters = Record<string, string>;

export type FilterType = 'options' | 'select' | 'string' | 'boolean';

export type FilterOption = {
    param?: string;
    title: string;
    value: any;
    checked: boolean;
    count?: number;
};

/**
 * Apply filters: from string like global_statuses=in_transit&originsISR,DEU,etc or searchBy=text
 */
function applyFilters(config: {
    state: Filters;
    filters: InputFilters;
    aliases: Record<string, string>;
    types: Record<string, FilterType>;
}): Filters {
    const { state, filters, aliases, types } = config;

    if (!filters) {
        return state;
    }

    const modifiedFilters = { ...state };

    forIn(filters, (value, key) => {
        const filterName = aliases[key] ?? key;
        const type = types[filterName] ?? 'string';

        if (!types.hasOwnProperty(filterName)) {
            return;
        }

        if (type === 'options') {
            const filterValues = value.split(',');

            if (!modifiedFilters[filterName]) {
                modifiedFilters[filterName] = filterValues.map(v => ({
                    value: v,
                    checked: true
                }));
            } else {
                modifiedFilters[filterName] = state[filterName].map((option: FilterOption) => ({
                    ...option,
                    checked: filterValues.some(v => v == option.value)
                }));
            }

            return;
        }

        if (type === 'select') {
            modifiedFilters[filterName] = value.split(',');
            return;
        }

        if (type === 'boolean') {
            modifiedFilters[filterName] = !!parseInt(value);

            return;
        }

        modifiedFilters[filterName] = value;
    });

    return modifiedFilters;
}

function parseQueryParams(config: {
    filters: InputFilters;
    aliases: Record<string, string>;
    types: Record<string, FilterType>;
}): Filters {
    const { filters, aliases, types } = config;

    if (!filters) {
        return {};
    }

    const modifiedFilters = {};

    forIn(filters, (value, key) => {
        const filterName = aliases[key] ?? key;
        const type = types[filterName] ?? 'string';

        if (!types.hasOwnProperty(filterName)) {
            return;
        }

        if (type === 'options') {
            const filterValues = value.split(',');

            modifiedFilters[filterName] = filterValues.map(v => ({
                value: convertStringToNumberIfPossible(v),
                name: v
            }));

            return;
        }

        if (type === 'select') {
            modifiedFilters[filterName] = value.split(',');
            return;
        }

        if (type === 'boolean') {
            modifiedFilters[filterName] = !!parseInt(value);

            return;
        }

        modifiedFilters[filterName] = value;
    });

    return modifiedFilters;
}

function extractValues(filters: ListFilterSet): ListFilterRaw {
    return chain(filters)
        .omitBy(value => isEmpty(value))
        .mapValues(v => (Array.isArray(v) ? v.map(item => extractFilterValue(item)) : extractFilterValue(v)))
        .value();
}

function extractValuesForHumans(filters: ListFilterSet): ListFilterRaw | null {
    if (Object.keys(filters).length === 0) {
        return null;
    }

    return chain(filters)
        .omitBy(value => isEmpty(value))
        .mapValues(v => {
            return Array.isArray(v)
                ? v.map(item => extractFilterValueForHumans(item)).join(', ')
                : extractFilterValueForHumans(v);
        })
        .value();
}

function extractFilterValue(v: any): any {
    return typeof v == 'object' && 'value' in v ? v.value : v;
}

function extractFilterValueForHumans(v: any): any {
    if (typeof v == 'object' && 'name' in v) {
        return v.name;
    }

    if (isPlainObject(v)) {
        return Object.values(v).join(', ');
    }

    return v;
}

function rename(filters: ListFilterRaw, map: Record<string, string>): ListFilterRaw {
    const renamedFilters: ListFilterRaw = {};

    forIn(filters, (value, key) => {
        const renamedKey = map[key] ?? key;
        renamedFilters[renamedKey] = value;
    });

    return renamedFilters;
}

function resetSignals(signals: any): void {
    forIn(signals, (value: WritableSignal<any>) => {
        value.set(null);
    });
}

function setSignals(signals: WritableSignal<any>[], values: ListFilterSet, defaults: ListFilterSet = {}): void {
    forEach(signals, (signal: WritableSignal<any>, key) => {
        signal.set(values[key] ?? defaults[key] ?? null);
    });
}

export function mergeFacets<FS extends IFacets>(refreshedFacets: FS, initialFacets: FS = null): FS {
    if (!initialFacets) {
        return refreshedFacets;
    }

    return {
        ...initialFacets,
        ...mapValues(refreshedFacets, (options, key) =>
            unionBy(
                options,
                initialFacets[key]?.map(f => ({ ...f, count: 0 })),
                'value'
            )
        )
    };
}

function convertStringToNumberIfPossible(s: string): string | number {
    const value = Number(s);
    return isNaN(value) ? s : value;
}

function removeCount(value: ListFilterValue): ListFilterValue {
    if (Array.isArray(value)) {
        return value.map(v => removeCount(v));
    }

    return typeof value === 'object' && 'count' in value ? omit(value, 'count') : value;
}

function isEmpty(value: ListFilterValue): boolean {
    return value === null || value === false || value === '' || (Array.isArray(value) && value.length === 0);
}

function toFilterOptions<T>(items: T[], valueResolver: (item: T) => unknown): FacetedFilter[] {
    return chain(items)
        .groupBy(valueResolver)
        .map((itemsInGroup, value) => ({
            name: value === 'null' ? 'none' : value,
            value: value,
            count: itemsInGroup.length
        }))
        .value();
}

export interface WhereCondition {
    path: string;
    type: 'exact' | 'includes' | 'some';
    value: any;
}

function where<T>(items: T[], conditions: WhereCondition[]): T[] {
    return items.filter(item => {
        return conditions.every(condition => {
            if (!condition || condition.value === undefined || condition.value === null) {
                return true;
            }

            const value = get(item, condition.path);

            if (condition.type === 'exact') {
                return value === condition.value;
            } else if (condition.type === 'includes') {
                return String(value).toLowerCase().includes(String(condition.value).toLowerCase());
            } else if (condition.type === 'some') {
                return value.includes(condition.value);
            }

            return true;
        });
    });
}

export const filterUtils = {
    apply: applyFilters,
    extractValues: extractValues,
    extractValuesForHumans: extractValuesForHumans,
    setSignals: setSignals,
    rename: rename,
    mergeFacets: mergeFacets,
    parseQueryParams: parseQueryParams,
    removeCount: removeCount,
    isEmpty: isEmpty,
    toFilterOptions: toFilterOptions,
    where: where
};
