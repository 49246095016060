import { IAddress } from '@models/address';
import { IContact } from '@models/contact';
import { IHub } from '@models/warehouse';
import { IUser } from '@models/user';
import { IPartnerService } from '@models/partner-service';
import { IOrganisation } from '@models/organisation';
import { IntegrationConnection } from '@modules/cockpit/integrations/integrations.types';
import { ITicketable } from '@models/ticket';
import { IFileable } from '@models/part-master';
import { ITag } from '@models/tag';

export interface IPartner extends ITicketable, IFileable {
    display_name: string;
    id: number;
    logo_path?: string;
    icon_path?: string;
    style_path?: string;
    slug: string;
    properties: any;
    clients?: any[];
    managers?: any[];
    assigned_users?: number[];
    organisations?: IOrganisation[];
    users?: IUser[];
    in_charge_manager_id: number;
    fee_percent: number;

    hubs?: IHub[];
    partner_services?: IPartnerService[];
    address?: IAddress;
    contact?: IContact;
    manager_on_shift?: {
        id: number;
        name: string;
        avatar: string;
    };
    manager_in_charge?: IUser;

    partner_settings?: { [key: string]: any };
    timeline?: IPartnerTimelineEvent[];
    description?: IPartnerDescription;
    tags: ITag[];

    is_selected?: boolean;
    _type: string;
}

export class Partner implements IPartner {
    display_name: string;
    id: number;
    logo_path?: string;
    icon_path?: string;
    style_path?: string;
    slug: string;
    properties: any;
    clients?: any[];
    managers?: any[];
    users?: IUser[];
    in_charge_manager_id: number;
    fee_percent: number;

    hubs?: IHub[];
    partner_services?: IPartnerService[];
    description?: IPartnerDescription;
    tags: ITag[];

    manager_on_shift?: {
        id: number;
        name: string;
        avatar: string;
    };
    manager_in_charge?: IUser;
    _type: string = 'partner';

    constructor(partner?: IPartner) {
        this.in_charge_manager_id = partner?.in_charge_manager_id || null;
        this.logo_path = partner?.logo_path || null;
        this.icon_path = partner?.icon_path || null;
        this.display_name = partner?.display_name || '';
        this.hubs = partner?.hubs || [];
        this.partner_services = partner?.partner_services || [];
        this.fee_percent = partner?.fee_percent;
    }
}

export interface IPartnerBillingState {
    income: number;
    balance: number;
    available: number;
    debt: number;
    spent?: number;
}

export interface IPartnerBillingReport {
    services: Array<{
        connection: IntegrationConnection;
        qty: number;
        averageRate: number;
        totalAmount: number;
    }>;

    total: number;
}

export interface IPartnerTimelineEvent {
    id: number;
    partner_id: number;
    date: string;
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
}

export interface IPartnerDescription {
    id: number;
    partner_id: number;
    industry: string;
    description: string;
    logistics_system: string;
    uses_system_ui: boolean;
    uses_excel_upload: boolean;
    uses_rest_api: boolean;
    uses_email: boolean;
    uses_items: boolean;
    uses_boxes: boolean;
    uses_pallets: boolean;
}
