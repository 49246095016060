import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'boolToString'
})
export class BoolToStringPipe implements PipeTransform {
    public static transform(value: boolean = null): string {
        if (value === null) {
            return '-';
        }

        return value ? 'Yes' : 'No';
    }

    public transform(value: boolean = null): string {
        return BoolToStringPipe.transform(value);
    }
}
