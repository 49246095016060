import { IPartner } from '@models/partner';
import { IBubbleCategory } from '@models/bubble-category';
import { IUser } from '@models/user';
import { IBubbleHistory } from '@models/bubble-history';
import { ITicket, ITicketable } from '@models/ticket';
import { IActivitable, IActivity } from '@models/activity.interface';
import { ITeam } from '@models/team';
import { EnumValueOptions, Identifiable } from '@core/types/model.types';
import { FacetedFilter } from '@core/types/filter.types';

export interface IBubble extends ITicketable, IActivitable {
    id: number;
    partner_id: number;
    category_id: number;
    bubbleable_type: string;
    bubbleable_id: number;
    priority: number;
    status: string;
    status_code: string;
    active: boolean;
    due_date: string | null;
    due_date_changed: boolean;
    pop_on: string | null;
    pinned: boolean;
    position: number;
    message: string;
    context: Record<string, any> | null;
    created_at: string | null;
    updated_at: string | null;

    // relations
    partner?: IPartner;
    category?: IBubbleCategory;
    bubbleable?: IBubbleable;
    assigned_users?: IUser[];
    followed_users?: IUser[];
    assigned_teams?: ITeam[];
    followed_teams?: ITeam[];
    history?: IBubbleHistory[];
    tickets?: ITicket[];
    activities?: IActivity[];

    // appends
    priority_options: EnumValueOptions;
    status_options: EnumValueOptions;
    countdown?: number;
    total_time?: number;
}

export interface IBubbleable extends Identifiable {
    id: number;
    partner_id: number;
    bubbles?: IBubble[];
}

export type IBubbleSections = {
    bubbleables?: FacetedFilter[];
    actions?: FacetedFilter[];
};

export type IPartnerBubbleState = {
    closest_hot_bubble: { expires_at: string; minutes_left: number };
};

export type IBubbleStatePerPartner = Record<number, IPartnerBubbleState>;

export type IBubbleFilter = {
    bubbleable_type?: string;
    partner_id?: number;
    category_id?: number;
    priority?: number;
    status?: string;
    assignee_id?: number;
    team_id?: number;
    action_id?: number;
    with?: string;
    order_by?: string;
    page?: number;
    include_facets?: boolean;
    page_entries?: number;
};

export type IBubbleFacet = {
    active?: FacetedFilter[];
    statuses?: FacetedFilter[];
    priorities?: FacetedFilter[];
    partners?: FacetedFilter[];
    categories?: FacetedFilter[];
    assignees?: FacetedFilter[];
    teams?: FacetedFilter[];
};

export const bubbleListRelations = [
    'partner',
    'category',
    'assignedUsers:(id,name,avatar)',
    'followedUsers:(id,name,avatar)',
    'assignedTeams:(id,name)',
    'followedTeams:(id,name)'
];

export const bubbleDetailRelations = [
    'bubbleable',
    'partner',
    'category',
    'category.actions',
    'assignedUsers:(id,name,avatar)',
    'followedUsers:(id,name,avatar)',
    'assignedTeams:(id,name)',
    'followedTeams:(id,name)'
];
