import { IContact } from '@models/contact';
import { ICustomer } from '@models/customer';
import { IHub } from '@models/warehouse';
import { ICountrySpecialInstruction } from '@models/country';
import { IPartner } from '@models/partner';
import { IWeatherResponse } from '@models/weather';

export interface IAddress {
    id: number;
    ref: string;
    ref2: string;
    address_name: string;
    address: string;
    address2: string;
    address3: string;
    city: string;
    country: string;
    state: string;
    formatted_address: string;
    contacts?: IContact[];
    contact?: IContact;
    customer?: ICustomer;
    country_iso_2: string;
    properties: any;
    zip: string;
    shipping_instructions?: string;
    address_type: string;
    outbound_inventory_conversions?: IHub[];
    inbound_inventory_conversions?: IHub[];
    lat: number;
    lng: number;
    country_special_instruction?: ICountrySpecialInstruction;
    valid?: boolean;
    full_address: string;
    label?: string;
    partner_id: number;
    customer_id: number;
    partner?: IPartner;

    //appends:
    weather?: IWeatherResponse;
}

export const isAddress = (a: object): a is IAddress => (a as IAddress).address_name !== undefined;
