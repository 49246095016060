import { Component, computed, input } from '@angular/core';

@Component({
    selector: 'app-device-preview-battery',
    template: ` <div class="flex flex-col items-center">
        <span class="relative">
            <mat-icon
                [svgIcon]="icon()"
                [ngClass]="[color(), 'icon-size-' + size()]"
                [matTooltip]="expanded() ? null : level()"
            ></mat-icon>
            <div
                *ngIf="expanded()"
                class="absolute inline-flex items-center justify-center w-2 h-2 text-xs font-bold text-white bg-red-500 rounded-full right-0"
                matTooltip="Battery is not accurate at this stage"
            ></div>
        </span>
        <span class="text-sm" *ngIf="expanded()">{{ level() }}</span>
    </div>`
})
export class DevicePreviewBatteryComponent {
    size = input<number>(8);
    battery = input<number | null>(null);
    expanded = input<boolean>(true);

    tooltip = computed(() => (!this.expanded ? this.level() : null));
    icon = computed(() =>
        this.battery() === null
            ? 'logivice:battery_problem'
            : this.battery() < 30
            ? 'logivice:battery_25'
            : this.battery() < 70
            ? 'logivice:battery_50'
            : 'logivice:battery_100'
    );
    color = computed(() =>
        this.battery() === null
            ? 'text-gray-500'
            : this.battery() < 30
            ? 'text-red-600'
            : this.battery() < 70
            ? 'text-yellow-500'
            : 'text-green-600'
    );
    level = computed(() =>
        this.battery() === null ? 'No' : this.battery() < 30 ? 'Low' : this.battery() < 70 ? 'Medium' : 'High'
    );
}
