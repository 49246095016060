/* eslint-disable @typescript-eslint/naming-convention */

import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { IUser } from '@models/user';
import { UserService } from '@core/services/resource/user.service';
import { IPagination } from '@models/pagination';
import { ITeam } from '@models/team';
import { TeamsService } from '@core/services/resource/teams.service';
import {
    SelectableModel,
    SelectorModel
} from '@shared/components/model-selector/model-selector.component';
import { ICourier } from '@models/courier';
import { CouriersService } from '@core/services/resource/couriers.service';
import { AddressesService } from '@core/services/resource/addresses.service';
import { IAddress } from '@models/address';
import { PartnerDevicesService } from '@core/services/resource/partner/devices.service';
import { ICustomer } from '@models/customer';
import { IContact } from '@models/contact';
import { IDevice } from '@models/device';
import { omit } from 'lodash-es';
import { ShipmentsService } from '@core/services/resource/ucontrol/shipments.resource';
import { IPointOfInterestTarget } from '@models/point-of-interest';
import { IShipment } from '@models/shipment';
import { PartnerOrdersService } from '@core/services/resource/partner/orders.service';
import { IOrder } from '@models/order';
import { PointsOfInterestService } from '@core/services/resource/ucontrol/points-of-interest.service';
import { IOrderItem } from '@models/order-item';
import { ExtendedListableResponseType } from '@core/types/http/listable-response.type';
import { TrackingService } from '@core/services/resource/ucontrol/tracking.service';
import { ICourierTracking, ICourierTrackingFacet } from '@models/courier-tracking';

interface Data {
    search: string;
    filter: any;
    overwrite: boolean;
    page?: number;
    params?: Record<string, any>;
}

export interface ModelSelectorState {
    data?: IPagination<SelectableModel>;
    loading: boolean;

    model?: SelectorModel;
}

@Injectable()
export class ModelSelectorStore extends ComponentStore<ModelSelectorState> {
    readonly setModel = this.updater(
        (state: ModelSelectorState, model: SelectorModel) => ({
            ...state,
            model
        })
    );

    readonly setLoading = this.updater(
        (state: ModelSelectorState, loading: boolean) => ({
            ...state,
            loading
        })
    );

    readonly updateData = this.updater(
        (
            state: ModelSelectorState,
            update: { data: IPagination<SelectableModel>; overwrite: boolean }
        ) =>
            update.overwrite
                ? { ...state, data: { ...update.data } }
                : {
                      ...state,
                      data: {
                          ...update.data,
                          data: [...(state.data.data || []), ...update.data.data]
                      }
                  }
    );

    readonly loading$ = this.select(state => state.loading);
    readonly data$ = this.select(state => state.data);
    readonly model$ = this.select(state => state.model);

    readonly loadUsers = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.userService
                    .loadUsers({
                        'name|email|nickname': '*' + data.search + '*',
                        'page': data.page || 1
                    })
                    .pipe(
                        tap({
                            next: (users: IPagination<IUser>) =>
                                this.updateData({
                                    data: users,
                                    overwrite: data.overwrite
                                })
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly loadTeams = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.teamsService
                    .loadTeams({
                        'name|description|nickname': '*' + data.search + '*',
                        'page': data.page || 1
                    })
                    .pipe(
                        tap({
                            next: (teams: IPagination<ITeam>) =>
                                this.updateData({
                                    data: teams,
                                    overwrite: data.overwrite
                                })
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly loadDevices = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.partnerDevicesService
                    .loadDevices(
                        data.filter.partner_slug,
                        {
                            'ref|id': '*' + data.search + '*',
                            'page': data.page || 1,
                            'with': 'tags:(id,name,color)',
                            ...omit(data.filter, ['except'])
                        },
                        data.filter?.except
                            ? [
                                  {
                                      param: 'id',
                                      operator: '!=',
                                      value: data.filter.except
                                  }
                              ]
                            : []
                    )
                    .pipe(
                        tap({
                            next: (devices: IPagination<IDevice>) =>
                                this.updateData({
                                    data: devices,
                                    overwrite: data.overwrite
                                })
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly loadPOITargets = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.pointsOfInterestService
                    .loadPOITargets(
                        {
                            ...this.searchByNameOrCode(data.search),
                            page: data.page || 1,
                            ...omit(data.filter, ['except'])
                        },
                        data.filter?.except
                            ? [
                                  {
                                      param: 'id',
                                      operator: '!=',
                                      value: data.filter.except
                                  }
                              ]
                            : []
                    )
                    .pipe(
                        tap({
                            next: (targets: IPagination<IPointOfInterestTarget>) =>
                                this.updateData({
                                    data: targets,
                                    overwrite: data.overwrite
                                })
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly loadCouriers = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.couriersService
                    .loadCouriers({
                        'slug|display_name': '*' + data.search + '*',
                        'page': data.page || 1
                    })
                    .pipe(
                        tap({
                            next: (couriers: IPagination<ICourier>) =>
                                this.updateData({
                                    data: couriers,
                                    overwrite: data.overwrite
                                })
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    //Addresss is not a typo (just workaround for plural form with suffix es :) )
    readonly loadAddresss = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.addressesService
                    .loadAddresses({
                        'address|country|country_iso_2|city|zip':
                            '*' + data.search + '*',
                        'page': data.page || 1,
                        ...data.filter
                    })
                    .pipe(
                        tap({
                            next: (addresses: IPagination<IAddress>) =>
                                this.updateData({
                                    data: addresses,
                                    overwrite: data.overwrite
                                })
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly loadCustomers = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.addressesService
                    .loadCustomers({
                        'name|ref': '*' + data.search + '*',
                        'page': data.page || 1,
                        ...data.filter
                    })
                    .pipe(
                        tap({
                            next: (customers: IPagination<ICustomer>) =>
                                this.updateData({
                                    data: customers,
                                    overwrite: data.overwrite
                                })
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly loadContacts = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.addressesService
                    .loadContacts({
                        'first_name|last_name|phone|email': '*' + data.search + '*',
                        'page': data.page || 1,
                        ...data.filter
                    })
                    .pipe(
                        tap({
                            next: (contacts: IPagination<IContact>) =>
                                this.updateData({
                                    data: contacts,
                                    overwrite: data.overwrite
                                })
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly loadShipments = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.shipmentsService
                    .loadShipments(
                        {
                            'ref|id': '*' + data.search + '*',
                            'page': data.page || 1,
                            'with': [
                                'fromShipmentAddress',
                                'toShipmentAddress'
                            ].join(','),
                            ...omit(data.filter, ['except'])
                        },
                        data.filter?.except
                            ? [
                                  {
                                      param: 'id',
                                      operator: '!=',
                                      value: data.filter.except
                                  }
                              ]
                            : []
                    )
                    .pipe(
                        tap({
                            next: (shipments: IPagination<IShipment>) =>
                                this.updateData({
                                    data: shipments,
                                    overwrite: data.overwrite
                                })
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly loadOrders = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.ordersService
                    .loadOrders(
                        data.params?.partner_id ?? data.params?.partner?.slug,
                        {
                            'ref|id|ref2|remarks': '*' + data.search + '*',
                            'page': data.page || 1,
                            'with': 'contact.address.customer',
                            ...omit(data.filter, ['except'])
                        },
                        data.filter?.except
                            ? [
                                  {
                                      param: 'id',
                                      operator: '!=',
                                      value: data.filter.except
                                  }
                              ]
                            : []
                    )
                    .pipe(
                        tap({
                            next: (
                                orders: ExtendedListableResponseType<IOrder, any>
                            ) => {
                                this.updateData({
                                    data: orders.results,
                                    overwrite: data.overwrite
                                });
                            }
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly loadOrderItems = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.ordersService
                    .loadOrderItems(
                        data.params?.partner_id,
                        data.params?.order_id,
                        {
                            'part_master.item': '*' + data.search + '*',
                            'page': data.page || 1,
                            'with': 'partMaster',
                            ...omit(data.filter, ['except'])
                        },
                        data.filter?.except
                            ? [
                                  {
                                      param: 'id',
                                      operator: '!=',
                                      value: data.filter.except
                                  }
                              ]
                            : []
                    )
                    .pipe(
                        tap({
                            next: (orderItems: IPagination<IOrderItem>) => {
                                this.updateData({
                                    data: orderItems,
                                    overwrite: data.overwrite
                                });
                            }
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly loadCourierTrackings = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            tap(() => this.setLoading(true)),
            switchMap(data =>
                this.courierTrackingService
                    .loadTrackings(
                        {
                            ...(data.search ? { q: data.search } : {}),
                            page: data.page || 1,
                            query_by: 'tracking_number',
                            ...omit(data.filter, ['except'])
                        },
                        data.filter?.except
                            ? [
                                  {
                                      param: 'id',
                                      operator: '!=',
                                      value: data.filter.except
                                  }
                              ]
                            : []
                    )
                    .pipe(
                        tap({
                            next: (
                                response: ExtendedListableResponseType<
                                    ICourierTracking,
                                    ICourierTrackingFacet
                                >
                            ) =>
                                this.updateData({
                                    data: response.results,
                                    overwrite: data.overwrite
                                })
                        })
                    )
            ),
            tap(() => this.setLoading(false))
        )
    );

    readonly load = this.effect((data$: Observable<Data>) =>
        data$.pipe(
            withLatestFrom(this.model$),
            tap(([data, model]) => {
                this['load' + model + 's'](data);
            })
        )
    );

    readonly loadNextPage = this.effect(
        (input$: Observable<{ search: string; filter: any }>) =>
            input$.pipe(
                withLatestFrom(this.data$),
                map(([input, data]) => {
                    if (data.to < data.total) {
                        this.load({
                            search: input.search,
                            filter: input.filter,
                            overwrite: false,
                            page: data.current_page + 1
                        });
                    }
                    return of(null);
                })
            )
    );

    constructor(
        private userService: UserService,
        private teamsService: TeamsService,
        private couriersService: CouriersService,
        private addressesService: AddressesService,
        private partnerDevicesService: PartnerDevicesService,
        private courierTrackingService: TrackingService,
        private shipmentsService: ShipmentsService,
        private pointsOfInterestService: PointsOfInterestService,
        private ordersService: PartnerOrdersService
    ) {
        super({ loading: false });
    }

    private searchByNameOrCode(search: string, codeLen: number = 3): object {
        if (!search) {
            return {};
        }

        return search.length === codeLen
            ? { code: search }
            : { name: '*' + search + '*' };
    }
}
